import React from 'react';

// map 'Icon Pack 3' in sketch; 'Humanistic - Filled' in design doc.
// exclude Social Icons
export const account = (
  <path
    fillRule='evenodd'
    d='M16.056 8.255a4.254 4.254 0 1 1-8.507 0 4.254 4.254 0 0 1 8.507 0zm3.052 11.71H4.496a.503.503 0 0 1-.46-.693 8.326 8.326 0 0 1 7.766-5.328 8.326 8.326 0 0 1 7.766 5.328.503.503 0 0 1-.46.694z'
  />
);

export const person = account;

export const magGlass = (
  <path
    fillRule='evenodd'
    d='M15.659 14.107c1.048.434 3.446 3.209 3.446 3.209a1.263 1.263 0 0 1 0 1.789 1.263 1.263 0 0 1-1.789 0s-2.775-2.371-3.209-3.419c-.077-.185 0-.591 0-.591l-.369-.362a6.111 6.111 0 0 1-3.656 1.211C6.729 15.944 4 13.265 4 9.972 4 6.679 6.729 4 10.082 4c3.354 0 6.082 2.679 6.082 5.972a5.88 5.88 0 0 1-1.466 3.878l.261.257s.483-.09.7 0zm-5.577.546c2.628 0 4.767-2.1 4.767-4.681s-2.139-4.681-4.767-4.681c-2.628 0-4.767 2.1-4.767 4.681s2.139 4.681 4.767 4.681z'
  />
);

export const cart1 = (
  <path
    fillRule='evenodd'
    d='M17.39 17.381c.714 0 1.306.593 1.306 1.31 0 .716-.592 1.31-1.305 1.31a1.317 1.317 0 0 1-1.305-1.31c0-.716.592-1.31 1.305-1.31zm-9.133 0c.713 0 1.305.593 1.305 1.31 0 .716-.592 1.31-1.305 1.31a1.317 1.317 0 0 1-1.305-1.31c0-.716.592-1.31 1.305-1.31zm9.765-2.061c.357 0 .673.376.673.734s-.295.735-.652.735H7.605a.658.658 0 0 1-.652-.655c0-.219.219-.653.408-1.005.139-.258.18-.554.119-.84L5.77 5.31H4.652A.659.659 0 0 1 4 4.655C4 4.297 4.296 4 4.652 4h1.774c.683 0 .704.819.805 1.31h12.117c.357 0 .652.297.652.655l-1.358 4.917a3.166 3.166 0 0 1-2.509 2.094l-7.356 1.133s.173.256.173.564c0 .308-.306.647-.306.647h9.378z'
  />
);

export const chevronUp = (
  <path
    fillRule='evenodd'
    d='M4.125 15.836c-.293.335-.039.84.424.84l14.813.021c.447 0 .697-.48.424-.81l-7.203-8.693a.559.559 0 0 0-.836-.012l-7.622 8.654z'
  />
);

export const chevronDown = (
  <path
    fillRule='evenodd'
    d='M19.774 7.86c.294-.335.04-.839-.423-.84L4.538 7c-.447-.001-.698.48-.425.81l7.204 8.693a.56.56 0 0 0 .836.011l7.621-8.654z'
  />
);

export const chevronLeft = (
  <path
    fillRule='evenodd'
    d='M15.836 19.774c.335.294.84.04.84-.423l.02-14.813c.001-.447-.48-.698-.81-.425l-8.693 7.204a.56.56 0 0 0-.01.836l8.653 7.621z'
  />
);

export const chevronRight = (
  <path
    fillRule='evenodd'
    d='M7.861 4.125c-.335-.293-.84-.039-.84.424L7 19.362c0 .446.481.697.811.424l8.693-7.203a.56.56 0 0 0 .011-.836L7.861 4.125z'
  />
);

export const arrowRight = (
  <path d='M13.557 7.50495L13.557 11.158L5 11.158C4.448 11.158 4 11.606 4 12.158C4 12.71 4.448 13.158 5 13.158L13.557 13.158L13.557 17.085C13.557 17.536 14.104 17.76 14.42 17.439L19.17 12.609C19.365 12.411 19.363 12.095 19.167 11.9L14.417 7.14895C14.1 6.83095 13.557 7.05595 13.557 7.50495Z' />
);

export const download = (
  <path
    fillRule='evenodd'
    d='M19.498 15.663c.272 0 .492.22.492.492V19.5c0 .272-.22.492-.492.492H4.492A.492.492 0 0 1 4 19.5v-3.346c0-.271.221-.492.492-.492h4.897a.49.49 0 0 1 .344.14l1.414 1.383a1.253 1.253 0 0 0 1.759-.008l1.381-1.372a.495.495 0 0 1 .347-.143h4.864zm-1.922 2.113a.752.752 0 0 0-.75-.75h-.192a.752.752 0 0 0-.75.75c0 .412.338.75.75.75h.192c.412 0 .75-.338.75-.75zm-.82-8.219c.45 0 .675.543.357.86l-4.751 4.75a.502.502 0 0 1-.71.003l-4.83-4.75a.504.504 0 0 1 .355-.863h3.927V5a1 1 0 0 1 2 0v4.557h3.653z'
  />
);

export const ok = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8c0-4.419 3.58-8 8-8zm3.26 6.794a.723.723 0 0 0 .057-1.045.772.772 0 0 0-1.067-.055l-3.598 3.053-.846-.932a.775.775 0 0 0-1.067-.054.726.726 0 0 0-.056 1.046l1.298 1.418a.84.84 0 0 0 .621.268.847.847 0 0 0 .558-.208l4.1-3.491z'
  />
);

export const freeship = (
  <path
    fillRule='evenodd'
    d='M20.205 9.972c.024.032.595.767.595 1.628v5.003c0 .75-.61 1.361-1.36 1.361h-.355a2.323 2.323 0 0 1-2.205 1.599 2.309 2.309 0 0 1-2.203-1.6h-.041a1.287 1.287 0 0 1-.613-.162 1.26 1.26 0 0 1-.613.163h-2.647a2.31 2.31 0 0 1-2.203 1.599 2.31 2.31 0 0 1-2.203-1.599H5.36c-.75 0-1.36-.611-1.36-1.361V5.31C4 4.588 4.587 4 5.31 4h8.11c.717 0 1.3.596 1.3 1.33v.91h2.48c.701 0 .93.31 1.133.584.04.053.081.109.115.151l.117.127v.058l1.619 2.787.021.025zM8.56 16.363a.871.871 0 0 0-.88.88c0 .494.387.88.88.88a.87.87 0 0 0 .88-.88.871.871 0 0 0-.88-.88zm9.2.88a.871.871 0 0 0-.88-.88.87.87 0 0 0-.88.88c0 .494.386.88.88.88a.87.87 0 0 0 .88-.88zm-3.176-5.947l4.591.111c-.054-.119-.117-.548-.147-.6l-1.851-3.124-2.6-.112.007 3.725z'
  />
);

export const globe = (
  <path
    fillRule='evenodd'
    d='M12 4c4.419 0 8 3.58 8 8a8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm4.702 10.998a1 1 0 0 0-.972-.765h-1.177v-1.922c0-.553-.449-1-1.002-1h-3.084l-.139-1.232s1.016.218 1.234 0c.411-.41 0-2.32 0-2.32h2.093a.997.997 0 0 0 .997-.998v-.829a6.427 6.427 0 0 0-2.309-.432c-2.866 0-5.299 1.865-6.162 4.443l3.463 3.402c-.039.57-.008 1.592.587 2.297.274.323 1.193.155 2.04.155h.03c.072 0 .098.01.113.008.142.21-.426 1.696-.816 2.65.245.026.492.045.745.045 2.3 0 4.321-1.206 5.476-3.015l-1.117-.487z'
  />
);

export const video = (
  <path
    fillRule='evenodd'
    d='M19.995 4C20.55 4 21 4.449 21 5.004v13.991c0 .554-.45 1.005-1.005 1.005H4.004A1.005 1.005 0 0 1 3 18.995V5.004C3 4.449 3.449 4 4.004 4h15.991zM19.5 6.528v-.143a.886.886 0 0 0-1.772 0v.143a.886.886 0 1 0 1.772 0zm0 3.765V9.8a.887.887 0 0 0-1.772 0v.493a.885.885 0 1 0 1.772 0zm0 3.765v-.493a.887.887 0 0 0-1.772 0v.493a.885.885 0 1 0 1.772 0zm0 3.555v-.283a.887.887 0 0 0-1.772 0v.283a.886.886 0 1 0 1.772 0zm-4.022-4.934a.75.75 0 0 0 0-1.5H8.577a.75.75 0 0 0 0 1.5h6.901zM6.327 6.501v-.088a.914.914 0 0 0-1.827 0v.088a.913.913 0 0 0 1.827 0zm0 3.764v-.437a.914.914 0 1 0-1.827 0v.437a.914.914 0 1 0 1.827 0zm0 3.765v-.437a.914.914 0 0 0-1.827 0v.437a.914.914 0 1 0 1.827 0zm0 3.556v-.228a.914.914 0 1 0-1.827 0v.228a.914.914 0 1 0 1.827 0z'
  />
);

export const hamburger = (
  <path
    fillRule='evenodd'
    d='M19 8H5a1 1 0 1 1 0-2h14a1 1 0 0 1 0 2zm0 5.097H5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2zm0 5.25H5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2z'
  />
);

export const buttonLeft = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm1.527 10.541l-2.282-2.54 2.297-2.556a.75.75 0 1 0-1.109-1.01l-3.219 3.566 3.206 3.551a.75.75 0 0 0 1.107-1.011z'
  />
);

export const buttonRight = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm-.434 11.565l3.22-3.567-3.206-3.55a.75.75 0 1 0-1.108 1.011l2.282 2.539-2.296 2.556a.75.75 0 0 0 1.108 1.011z'
  />
);

export const close = (
  <path
    fillRule='evenodd'
    d='M19.245 4.313a1.065 1.065 0 0 0-1.508 0L11.78 10.27 5.82 4.313A1.065 1.065 0 1 0 4.312 5.82l5.958 5.958-5.958 5.959a1.067 1.067 0 0 0 1.508 1.508l5.959-5.958 5.958 5.958a1.065 1.065 0 1 0 1.508-1.508l-5.958-5.959 5.958-5.958a1.065 1.065 0 0 0 0-1.508'
  />
);

export const location = (
  <path
    fillRule='evenodd'
    d='M11.637 4c3.658 0 6.641 2.763 6.636 6.162-.007 3.754-4.265 8.018-5.966 9.572a1.046 1.046 0 0 1-1.391.001C9.206 18.195 5 14.008 5 10.162 5 6.763 7.978 4 11.637 4zm0 8.776c1.694 0 3.073-1.28 3.073-2.854 0-1.573-1.379-2.853-3.073-2.853-1.695 0-3.073 1.28-3.073 2.853 0 1.574 1.378 2.854 3.073 2.854z'
  />
);

export const okCalendar = (
  <path
    fillRule='evenodd'
    d='M4 9.569h15.506v9.179c0 .55-.446.995-.995.995H5.002a.995.995 0 0 1-.996-.995v-8.84H4v-.34zm10.578 3.7a.73.73 0 0 0 .057-1.044.772.772 0 0 0-1.067-.055l-2.735 2.189-.846-.931a.772.772 0 0 0-1.066-.055.718.718 0 0 0-.251.513.72.72 0 0 0 .194.533l1.298 1.418a.845.845 0 0 0 .621.268.846.846 0 0 0 .559-.208l3.236-2.627zm4.928-5.2h-15.5V6.073c0-.549.446-.995.996-.995h1.965v-.239a.75.75 0 1 1 1.5 0v.239h6.579V4.75a.75.75 0 0 1 1.5 0v.328h1.965c.55 0 .995.446.995.995v1.996z'
  />
);

export const questionBubble = (
  <path
    fillRule='evenodd'
    d='M12 4c4.411 0 8 3.164 8 7.055 0 3.889-3.589 7.053-8 7.053a8.6 8.6 0 0 1-1.67-.171c-.466.225-1.465.616-3.737 1.39a.751.751 0 0 1-.922-1.027l.953-2.044C4.948 14.916 4 13.052 4 11.055 4 7.164 7.589 4 12 4zm.491 7.623c0-.721 1.748-.52 1.748-2.401 0-1.348-1.085-2.058-2.329-2.058-1.348 0-2.242.833-2.242 2.278h1.105c0-.792.35-1.234 1.178-1.234.71 0 1.193.309 1.193.993 0 1.218-1.717.714-1.717 2.411v.746h1.064v-.735zm-.524 2.915a.77.77 0 0 0 .771-.771.758.758 0 0 0-.771-.756.751.751 0 0 0-.756.756c0 .421.323.771.756.771z'
  />
);

export const digitalProduct = (
  <path
    fillRule='evenodd'
    d='M15.026 16.356c.283 0 .426.344.225.544l-3 3.006a.315.315 0 0 1-.45.002l-3.05-3.006a.318.318 0 0 1 .222-.546h2.482v-4.49a.632.632 0 0 1 1.262 0v4.49h2.31zm1.475-9.799c.627.079 1.828.35 2.653 1.287.686.779.957 1.823.805 3.101-.276 2.299-2.317 3.148-3.705 3.337h-2.213v-2.595c0-.901-1.055-1.635-1.954-1.635-.901 0-1.968.734-1.968 1.635v2.595h-2.23c-.816.002-2.16-.127-3.035-.901C4.295 12.886 4 12.192 4 11.375c0-1.091.333-1.933.989-2.503.646-.562 1.454-.706 1.998-.731C7.372 6.454 8.764 4 12.068 4c2.796 0 4.02 1.754 4.433 2.557z'
  />
);

export const money = (
  <path
    fillRule='evenodd'
    d='M18.981 7C19.544 7 20 7.456 20 8.019v7.458c0 .562-.456 1.018-1.019 1.018H5.019A1.019 1.019 0 0 1 4 15.477V8.019C4 7.456 4.456 7 5.019 7h13.962zm-2.108 5.513a.766.766 0 1 0-.001-1.531.766.766 0 0 0 .001 1.531zM12 14.424c1.187 0 2.153-1.201 2.153-2.676S13.187 9.073 12 9.073c-1.188 0-2.152 1.2-2.152 2.675 0 1.475.964 2.676 2.152 2.676zm-4.919-1.911a.765.765 0 1 0 0-1.53.765.765 0 0 0 0 1.53z'
  />
);

export const plus = (
  <path
    fillRule='evenodd'
    d='M19.799 11.899a1 1 0 0 0-1-1H12.9V5a1 1 0 0 0-2 0v5.899H5a1 1 0 1 0 0 2h5.9v5.899a1 1 0 1 0 2 0v-5.899h5.899a1 1 0 0 0 1-1'
  />
);

export const minus = (
  <path fill='currentColor' fillRule='nonzero' d='M5 13.5h14a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2z' />
);

export const clock = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M7.64 9.398L6.57 8.2a6.932 6.932 0 013.245-2.235 6.834 6.834 0 015.106.358 6.465 6.465 0 012.826 3.227 6.597 6.597 0 01.21 4.315 6.358 6.358 0 01-2.454 3.315 6.19 6.19 0 01-3.941 1.076 6.33 6.33 0 01-3.893-1.848 6.52 6.52 0 01-1.854-3.937.885.885 0 00-.341-.397.864.864 0 00-.502-.13.857.857 0 00-.505.207.881.881 0 00-.283.473 8.109 8.109 0 002.16 4.984 8.24 8.24 0 002.83 1.84 8.134 8.134 0 003.317.542 8.122 8.122 0 005.154-2.429 8.37 8.37 0 002.353-5.255 8.249 8.249 0 00-1.48-4.885 8.036 8.036 0 00-4.05-3.03 8.15 8.15 0 00-5.408.146A8.28 8.28 0 005.57 7.08L4.55 5.94a.237.237 0 00-.263-.074.238.238 0 00-.157.227L4 9.398c0 .064.024.126.065.175a.227.227 0 00.162.077l3.24.154a.235.235 0 00.218-.146.245.245 0 00-.045-.26zm4.44 7.07c2.422 0 4.386-1.996 4.386-4.458 0-2.461-1.964-4.457-4.386-4.457S7.694 9.55 7.694 12.01c0 2.462 1.964 4.457 4.386 4.457zm1.75-2.295l-2.473-1.636.064-2.788a.466.466 0 01.167-.33.452.452 0 01.352-.098.458.458 0 01.352.15.473.473 0 01.123.366l-.075 2.195 2.16 1.405a.488.488 0 01.261.332.501.501 0 01-.088.415c-.26.274-.584.154-.843-.011z'
    fill='currentColor'
  />
);

export const alert = (
  <path
    fill='currentColor'
    fillRule='evenodd'
    d='M12 20C13.5822 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4003 4.00347 8.93853 4.60897C7.47672 5.21447 6.22729 6.23985 5.34824 7.55544C4.46919 8.87103 4 10.4177 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87826 20 12 20ZM12.875 12.38V8.375C12.875 7.89175 12.4832 7.5 12 7.5C11.5167 7.5 11.125 7.89175 11.125 8.375V12.38C11.125 12.8632 11.5167 13.25 12 13.25C12.4832 13.25 12.875 12.8632 12.875 12.38ZM13.1 15.09C13.1 15.6975 12.6075 16.19 12 16.19C11.3925 16.19 10.9 15.6975 10.9 15.09C10.9 14.4825 11.3925 13.99 12 13.99C12.6075 13.99 13.1 14.4825 13.1 15.09Z'
  />
);

export const pause = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    fill='currentColor'
    d='M10 19H8V5H10V19ZM16 5H14V19H16V5Z'
  />
);
